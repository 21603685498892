<template>
  <iframe
    id="iframe-overview"
    ref="myElement"
    frameborder="1"
    :height="`${height}px`"
    width="100%"
    scrolling="no"
    :src="`${VUE_APP_BASE_AUTH_MODULE}/team`"
    allow="clipboard-read; clipboard-write"
  />

</template>

<script>
export default {
  data() {
    return {
      height: 0,
      VUE_APP_BASE_AUTH_MODULE: process.env.VUE_APP_BASE_AUTH_MODULE,
    }
  },
  mounted() {
    window.addEventListener('message', event => {
      if (event.origin === this.VUE_APP_BASE_AUTH_MODULE) {
        const { type, data } = event.data

        if (type === 'SET_DIMENSION') {
          this.height = Math.floor(data.height)
        }
      }
    })
  },

}
</script>
